import React, { useEffect, useState } from 'react';
import './bots-delete-modal.scoped.css';
import { Modal } from '@/components/Modal/Modal';
import promptImg from '@/assets/table-prompt-icon.png';
import { ReactComponent as ChatboxClose } from '@/assets/chatbox-close-icon-lm.svg';
import { deleteBotsIds } from '@/api/bots';
import { useAtom } from 'jotai';
import { ProgressBar } from '@/components/ProgressBar/ProgressBar'

import {
  chosenBotsAtom,
  markedBotsAtom,
  updateMarkedBotsAtom,
  searchBotAtom,
} from '@/atoms/bots';

function BotsDeleteModal({
  isOpen,
  onClose,
  header,
  subHeader,
  setBotsDetails,
  getAllBotsUser,
}) {
  const [markedBots] = useAtom(markedBotsAtom);
  const [, setMarkedBots] = useAtom(updateMarkedBotsAtom);
  const [, setSearchQuery] = useAtom(searchBotAtom);
  const [toShowBots, setToShowBots] = useState();
  const [, setChosenBots] = useAtom(chosenBotsAtom);
  const [isLoading, setIsLoading] = useState(false);

  // console.log("to-show-bots:",toShowBots)
  // console.log("delete-marked:",markedBots)

  useEffect(() => {
    setToShowBots(markedBots);
  }, []);

  const deleteBots = async () => {
    try {
      setIsLoading(true);
      let BotsIds = toShowBots.map((toShowBots) => toShowBots.botId);
      console.log('bots ids to delete:', BotsIds);

      BotsIds.forEach((botId) => {
        setChosenBots((prev) => {
          return prev.filter((item) => item !== botId);
        });
      });
      // await axios.delete(`/api/bots/${toShowBots[0].botId}`)
      await deleteBotsIds(BotsIds);
      setBotsDetails((prev) => prev.filter((bot) => !toShowBots.includes(bot.botId)));
      setMarkedBots({ type: 'markAllBots', payload: { val: [] } });
      setSearchQuery('');
      await getAllBotsUser();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };
  const handleBotsForDelete = (bot) => {
    setToShowBots((prev) => prev.filter((item) => item.botId.trim() !== bot.botId.trim()));
    setMarkedBots({ type: 'markBot', payload: { val: bot } });
  };
  return (
    <Modal header={header} subHeader={subHeader} onClose={onClose} isOpen={isOpen}>
      {!isLoading ? (
        <>
          <div className="flex-bots">
            {toShowBots &&
              toShowBots.map((bot) => {
                return (
                  <div className="bot" key={bot.botId}>
                    <div className="icon-name-container">
                      <img src={promptImg} /> {bot.bot_name}
                    </div>
                    <button type="button" onClick={() => handleBotsForDelete(bot)}>
                      <ChatboxClose />
                    </button>
                  </div>
                );
              })}
          </div>
          <div className="flex-btns-actions">
            <button onClick={onClose}>cancel</button>
            <button onClick={deleteBots}>delete</button>
          </div>
        </>
      ) : (
        <ProgressBar isLoading={isLoading} key={'delete-progress'} />
      )}
    </Modal>
  );
}

export default BotsDeleteModal;
