export function handleToggleFolder(markedFilesState, folderId, topLevelFolders) {
  toggleFolderMark(folderId, topLevelFolders);

  return [markedFilesState, topLevelFolders];
  function updateMarkedFilesState(file, isMarked) {
    if (isMarked) {
      // Add file to markedFilesState if not already present
      if (!markedFilesState.includes(file.docId)) {
        markedFilesState.push({
          docId: file.doc_id,
          docTitle: file.doc_title,
          docType: file.doc_type,
          tags: file.tags,
          docDescription: file.doc_description,
          vectorMethods: file.vector_methods,
          docNameOrigin: file.doc_name_origin,
        });
      }
    } else {
      // Remove file from markedFilesState
      markedFilesState = markedFilesState.filter((doc) => doc.docId !== file.doc_id);
    }
  }

  function setMarked(folder, isMarked) {
    folder.marked = isMarked;
    folder.files.forEach((file) => {
      file.marked = isMarked;
      updateMarkedFilesState(file, isMarked);
    });
    folder.subfolders.forEach((subfolder) => setMarked(subfolder, isMarked));
  }

  function toggleFolderMark(folderId, topLevelFolders) {
    let folder = findFolderById(folderId, topLevelFolders);
    if (folder) {
      setMarked(folder, !folder.marked);
    }
  }

  function findFolderById(folderId, folders) {
    for (let folder of folders) {
      if (folder.id === folderId) {
        return folder;
      }
      let foundFolder = findFolderById(folderId, folder.subfolders);
      if (foundFolder) return foundFolder;
    }
    return null;
  }
}
