import './bots-table.scoped.css';
import { useState } from 'react';
import BotsTableRow from '@/components/BotsTab/BotsTable/BotsTableRow/BotsTableRow';
import { useAtom } from 'jotai';
import moment from 'moment';
import { markedBotsAtom, updateMarkedBotsAtom } from '@/atoms/bots';
import TableHeaderSort from '@/components/TableHeaderSort/TableHeaderSort';

const BotsTable = ({ botsToShowUser, botUrl, settings }) => {
  const [, setMarkedBots] = useAtom(updateMarkedBotsAtom);
  const [markedBots] = useAtom(markedBotsAtom);
  const [isAllMarked, setIsAllMarked] = useState(false);
  const [sortOrder, setSortOrder] = useState(1);

  const onClickRow = (botDetails) => {
    setMarkedBots({ type: 'markBot', payload: { val: botDetails } });
  };

  const handleMarkAllBots = () => {
    setMarkedBots({ type: 'markAllBots', payload: { val: isAllMarked ? [] : botsToShowUser } });
    setIsAllMarked(!isAllMarked);
  };

  function handleSort(arr, key) {
    arr.sort((a, b) => {
      if (key === 'last_updated') {
        const dateA = moment(a[key], 'DD MMM, YYYY').valueOf();
        const dateB = moment(b[key], 'DD MMM, YYYY').valueOf();
        return (dateA - dateB) * sortOrder;
      } else {
        const valA = a[key].toLowerCase();
        const valB = b[key].toLowerCase();
        return (valA > valB ? 1 : valA < valB ? -1 : 0) * sortOrder;
      }
    });
    setSortOrder(-sortOrder);
  }

  return (
    <>
      <table>
        <thead
          style={{
            backgroundColor: 'var(--chat-background-clr)',
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          <tr>
            {settings[0].options.map((header, index) => {
              return (
                <TableHeaderSort
                  key={index}
                  type={header.type}
                  value={header.type === 'header' ? header.value : markedBots.length}
                  style={{ ...header.style, width: header.type === 'header' ? 'calc(100% / 9)' : '' }}
                  handler={
                    header.type === 'header'
                      ? handleSort
                      : {
                          value:
                            botsToShowUser &&
                            markedBots.length === botsToShowUser.length &&
                            botsToShowUser.length > 0
                              ? true
                              : false,
                          func: handleMarkAllBots,
                        }
                  }
                  arr={botsToShowUser}
                  isSelect={header.isSelect}
                  table={settings[1].type}
                  isSort={header.isSort}
                />
              );
            })}
          </tr>
        </thead>
        <tbody>
          {botsToShowUser.map((botDetails, index) => (
            <BotsTableRow
              markedBot={markedBots.some((bot) => bot.botId === botDetails.botId)}
              onClick={() => onClickRow(botDetails)}
              key={index}
              botDetails={{ ...botDetails, botUrl: botUrl }}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default BotsTable;
