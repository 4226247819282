import './select-input.scoped.css';
import ArrowDown from '@/assets/select-arraow-down.png';

const SelectInput = ({ isDisable, isDropdown, handleDropdown, handleClick, options, selected }) => {
  return (
    <div className="custom-select-container">
      <div
        className="custom-select-selected"
        style={{ width: '170px', cursor: isDisable ? 'auto' : 'pointer' }}
        onClick={() => {
          if (!isDisable) handleDropdown();
        }}
      >
        <p className="sort-by-select">
          Sort by: {selected} <img src={ArrowDown} alt="" srcSet="" />
        </p>
      </div>
      {isDropdown && (
        <div className="custom-select-options">
          {options.map((option, index) => (
            <div key={index} className="custom-select-option" onClick={() => handleClick(option)}>
              <p>{option}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectInput;
