export const AVALIBLE_MODELS_TOOLKIT = {
  MISTRAL: 'mistral',
};

export const AVALIBLE_MODELS_BOT_GENERATOR = {
  'GPT 3.5': 'GPT 3.5',
  'GPT 4': 'GPT 4',
  'GPT-4o': 'GPT-4o',
  'GPT-4o-Mini': 'GPT-4o-Mini',
  MISTRAL: 'mistral',
  // GEMINI: 'GEMINI',
  'GEMINI 1.5 PRO': 'GEMINI-1.5-PRO',
  'GEMINI 1.5 FLASH': 'GEMINI-1.5-FLASH',
  'Gemini-pro-002': 'Gemini-pro-002',
  'Gemini-flash-002': 'Gemini-flash-002',
}