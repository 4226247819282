import io from 'socket.io-client';


let serviceInstance = null
class SocketIOService {
    #socket
    registeredEvents = []
    isConnected = false
    connect() {
        try {
            this.#socket = io({
                reconnection: true,
                // transports: ['websocket'],
                reconnectionAttempts: Infinity, // number of reconnection attempts before giving up
                reconnectionDelay: 1000, // initial delay for reconnection (ms)
                reconnectionDelayMax: 5000, // maximum delay for reconnection (ms)
                randomizationFactor: 0.5, // randomization factor for reconnection
            })
            this.isConnected = true
            this.addListeners()
            console.log(`Socket connected: ${this.isConnected}`)
        } catch (error) {
            console.error('Error connecting to socket:', error)
            this.isConnected = false
        }
    }
    disconnect() {
        if(!this.#socket) return
        this.#socket.disconnect()
        this.registeredEvents.forEach(event => this.#socket.off(event))
        this.registeredEvents = []
        this.isConnected = false
    }
    setConnectedStatus(status) {
        if(!status || typeof status !== 'boolean') {
            console.error(`Invalid status: ${status}`)
            return
        }
        console.log('Setting socket connected status:', status)
        this.isConnected = status
    }
    addListeners() {
        this.#socket.on("disconnect", () => {
            this.setConnectedStatus.bind(this, false)

        })
        this.#socket.on("connect", this.setConnectedStatus.bind(this, true))

        this.registeredEvents.forEach(({ event, callback, active }, i) => {
            console.log('Registering event:', event)
            if(!active) {
                console.log(`Activating event: ${event}`)
                this.#socket.on(event, callback)
                this.registeredEvents[i].active = true
            }
        })
    }
    registerEvent(event, callback) {
        const connected = this.isConnected
        if(this.registeredEvents.find(e => e.event === event)) {
            return
        }
        this.registeredEvents.push({ event, callback, active: connected })
        if(!connected) {
            console.warn(`Socket is not connected. Event ${event} will be registered once connected`)
            return
        }
        console.log('Registering event:', event)
        this.#socket.on(event, callback)

    }
    deRegisterEvent(event) {
        if(!this.#socket) return
        this.#socket.off(event)
        this.registeredEvents = this.registeredEvents.filter(e => e !== event)
    }
    emit(event, data) {
        if(!this.isConnected) {
            console.error('Socket is not connected')
            return
        }
        this.#socket.emit(event, data)
    }
}


export default function createSocketIOService() {
    if(!serviceInstance) {
        serviceInstance = new SocketIOService()
    }
    return serviceInstance
}
