import { atom } from 'jotai';

export const uploadStatusPlacementAtom = atom({ x: 0 });

export const uploadedDocsAtom = atom([]);

export const isExpandedAtom = atom(true);

export const isShowStateAtom = atom(false);

export const uploadTimeoutAtom = atom(10);
