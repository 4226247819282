import './voice-main.scoped.css';
import React, { useState, useEffect } from 'react';
// import VoiceTableGrid from '../VoiceTableGrid/VoiceTableGrid';
import VoiceTable from '../VoiceTable/VoiceTable';
import { useAtom } from 'jotai';
import { skeletonVoiceAtom } from '@/atoms/general';
import {
  filteredCallsAtom,
  callsToShowUserAtom,
  searchCallAtom,
  sortOptionAtom,
  timeToggleAtom,
} from '@/atoms/voice';
import { getVoiceCalls } from '@/api/voice.js';
import SkeletonTable from '@/components/SkeletonTable/SkeletonTable';
import SearchInput from '@/components/SearchInput/SearchInput';
import SelectInput from '@/components/SelectInput/SelectInput';
import { selectOptions } from '@/constants/table/selectOptions';
import headersSettings from '@/constants/table/headers';
import LowerNavbar from '@/components/VoiceTab/LowerNavbar/LowerNavbar';

function VoiceMain() {
  const [callsToShowUser, setCallsToShowUser] = useAtom(callsToShowUserAtom);
  const [isSkeleton, setIsSkeleton] = useAtom(skeletonVoiceAtom);
  const [filteredCalls] = useAtom(filteredCallsAtom);
  const [searchQuery, setSearchQuery] = useAtom(searchCallAtom);
  const [, setTimeToggle] = useAtom(timeToggleAtom);
  const [selectedOption, setSelectedOption] = useAtom(sortOptionAtom);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getVoiceData();
  }, []);

  const getVoiceData = async () => {
    try {
      setIsSkeleton(true);
      const data = await getVoiceCalls();
      setCallsToShowUser(data);
    } catch (error) {
      console.log('Failed to fetch voice calls-main:', error);
      setIsSkeleton(false);
    } finally {
      setIsSkeleton(false);
    }
  };

  const handleSearchChange = ({ target }) => {
    setSearchQuery(target.value);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setTimeToggle((prev) => !prev);
    setIsOpen(false);
  };

  return (
    <div className="documents-main-container">
      <div className="search-container">
        <SearchInput isDisable={isSkeleton} handleSearch={handleSearchChange} value={searchQuery} />
        <SelectInput
          isDisable={isSkeleton}
          isDropdown={isOpen}
          handleDropdown={toggleDropdown}
          handleClick={handleOptionClick}
          options={selectOptions}
          selected={selectedOption}
        />
      </div>
      <div>
        {!callsToShowUser.length && isSkeleton ? (
          <SkeletonTable />
        ) : (
          // <VoiceTableGrid key={123} callsToShowUser={callsToShowUser} />
          <VoiceTable key={123} callsToShowUser={filteredCalls} settings={headersSettings['voice']} />
        )}
      </div>
      <LowerNavbar />
    </div>
  );
}

export default VoiceMain;
