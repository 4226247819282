import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Modal } from '@/components/Modal/Modal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { v4 as uuidv4 } from 'uuid';
import { useAtom } from 'jotai';
import { userAtom, propertiesAtom } from '@/atoms/general';
import './add-edit-modal.scoped.css';
import { ReactComponent as UploadFile } from '@/assets/upload-document.svg';
import pdfImg from '@/assets/pdf-img.png';
import wordImg from '@/assets/word-img.png';
import { ReactComponent as OpenOptionsIcon } from '@/assets/vector-option-to-open-icon.svg';
import { ReactComponent as CloseOptionsIcon } from '@/assets/vector-option-to-close-icon.svg';
import { markedDocumentsAtom } from '@/atoms/documents';
import { uploadedDocsAtom, isShowStateAtom } from '@/atoms/upload';
import { ReactComponent as OptionSelected } from '@/assets/check-icon-active.svg';
import { ReactComponent as RemoveIcon } from '@/assets/remove-upload.svg';
import { ReactComponent as OptionUnSelected } from '@/assets/check-icon-lm.svg';
import createFileQueueService from '../../services/fileQueueService';
import { activeFolderAtom } from '@/atoms/folders';
import { SUPPORTED_FILE_EXTENSIONS } from '../../api/upload';

function AddEditModal({ isOpen, onClose, action, header, subHeader }) {
    const [toggle] = useState('file');
    const [displayedFiles, setDisplayedFiles] = useState([]);
    const [uploadedDocs, setUploadedDocs] = useAtom(uploadedDocsAtom);
    const [isShowStatus, setIsShowStatus] = useAtom(isShowStateAtom);
    const [isLoading, setIsLoading] = useState(false);
    const [properties,] = useAtom(propertiesAtom);
    const [isAdvancedOptionOpen, setIsAdvancedOptionOpen] = useState(false);
    const [apiModel, setApiModel] = useState('Gpt-4o');
    const [user] = useAtom(userAtom);
    const [blobUrl, setBlobUrl] = useState('')
    const [markedDocuments] = useAtom(markedDocumentsAtom);
    const [activeFolder] = useAtom(activeFolderAtom);

    // console.log('markedDocuments:', markedDocuments);

    let editedDocument = useRef(markedDocuments[0]);
    const company = user.email.split('@')[1];
    let vectorData = useRef();

    useMemo(() => {
        setBlobUrl(properties.urls?.blob_url);
    }, [properties]);

    if (action === 'edit') {
        vectorData.current = {
            title: {
                value: editedDocument.current.vectorMethods.includes('title'),
                label: 'Divide by Titles',
            },
            questions: {
                value: editedDocument.current.vectorMethods.includes('question'),
                label: 'Questions & Answers',
            },
            summary: {
                value: editedDocument.current.vectorMethods.includes('summary'),
                label: 'Document Summary',
            },
            recursive: {
                value: editedDocument.current.vectorMethods.includes('recursive'),
                label: 'recursive',
            },
            small: {
                value: editedDocument.current.vectorMethods.includes('small'),
                label: 'small',
            },
            llama: {
                value: editedDocument.current.vectorMethods.includes('llama'),
                label: 'llama',
            },
            large: {
                value: editedDocument.current.vectorMethods.includes('large'),
                label: 'large',
            },
            'titles-summary': {
                value: editedDocument.current.vectorMethods.includes('titles-summary'),
                label: 'titles-summary',
            },
            'titles-chapters': {
                value: editedDocument.current.vectorMethods.includes('titles-chapters'),
                label: 'titles-chapters',
            },
            Flex: {
                value: editedDocument.current.vectorMethods.includes('Flex'),
                label: 'Flex',
            },
        };
    }

    const [uploadData, setDocumentData] = useState(
        action == 'add'
            ? {
                title: '',
                // description: '',
                url: '',
                vectorLanguage: 'english (UK)',
                chunkSize: 500,
                chunkOverLap: 15,
                vectorMethods: {
                    // recursive: { value: false, label: 'recursive' },
                    llama: { value: false, label: 'llama' },
                    Text: { value: true, label: 'Text' },
                    'Text & Image': { value: false, label: 'Text & Image' },
                    Flex: { value: false, label: 'Flex' },
                    // title: { value: false, label: 'Divide by Titles' },
                    questions: { value: false, label: 'Questions & Answers' },
                    summary: { value: false, label: 'Document Summary' },
                    small: { value: false, label: 'small embedding' },
                    large: { value: false, label: 'large embedding' },
                    'titles-summary': { value: false, label: 'titles-summary' },
                    'titles-chapters': { value: false, label: 'titles-chapters' },
                },
            }
            : {
                title: editedDocument.current.docTitle,
                // description: editedDocument.current.docDescription,
                url: editedDocument.current.url ? editedDocument.current.url : ' ',
                vectorLanguage: 'english (UK)',
                vectorMethods: {
                    llama: {
                        llama: editedDocument.current.vectorMethods.includes('llama'),
                        label: 'llama',
                    },
                    Text: {
                        Text: editedDocument.current.vectorMethods.includes('Text'),
                        label: 'Text',
                    },
                    Flex: {
                        Flex: editedDocument.current.vectorMethods.includes('Flex'),
                        label: 'Flex',
                    },
                    'Text & Image': {
                        'Text & Image': editedDocument.current.vectorMethods.includes('Text & Image'),
                        label: 'Text & Image',
                    },
                    // recursive: {
                    //   recursive: editedDocument.current.vectorMethods.includes('recursive'),
                    //   label: 'recursive',
                    // },
                    // title: {
                    //   value: editedDocument.current.vectorMethods.includes('title'),
                    //   label: 'Divide by Titles',
                    // },
                    summary: {
                        value: editedDocument.current.vectorMethods.includes('summary'),
                        label: 'summary',
                    },
                    'titles-summary': {
                        value: editedDocument.current.vectorMethods.includes('titles-summary'),
                        label: 'titles-summary',
                    },
                    'titles-chapters': {
                        value: editedDocument.current.vectorMethods.includes('titles-chapters'),
                        label: 'titles-chapters',
                    },

                    questions: {
                        value: editedDocument.current.vectorMethods.includes('question'),
                        label: 'Questions & Answers',
                    },
                    small: {
                        value: editedDocument.current.vectorMethods.includes('small'),
                        label: 'Document small',
                    },
                },
                large: {
                    value: editedDocument.current.vectorMethods.includes('large'),
                    label: 'Document large',
                },
            }
    );

    const handleRemoveFile = (docId) => {
        setDisplayedFiles((prev) => prev.filter((file) => file.id !== docId));
    };

    const getValidFiles = (files = []) => {
        const validFilesList = files.filter((file) => Object.values(SUPPORTED_FILE_EXTENSIONS).includes(file.name.split('.').pop()));
        return validFilesList
    }
    const handleFiles = async (files) => {
        const filesArr = Array.from(files)
        const validFiles = getValidFiles(filesArr);

        const filesArray = validFiles.map(
            (file) => (
                (file.id = Date.now() + file.name),
                (file.status = 'pending'),
                (file.progress = 0),
                (file.finished = false),
                (file.content = file)
            )
        );
        setDisplayedFiles([...displayedFiles, ...filesArray]);
    };
    const handleDragOver = (e) => {
        e.preventDefault();
        e.target.classList.add('hover');
    }
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.target.classList.remove('hover');
        // e.target.style.border = '2px dashed #E1E1E1';
    }
    const handleChangeVectorMethod = (type) => {
        setDocumentData((prev) => {
            return {
                ...prev,
                vectorMethods: {
                    ...prev.vectorMethods,
                    [type]: {
                        ...prev.vectorMethods[type],
                        value: !prev.vectorMethods[type].value,
                    },
                },
            };
        });
    };

    const isIncludeFlex = () => {
        let chosenVectorMethods = Object.keys(uploadData.vectorMethods).filter(
            (vectorMethod) => uploadData.vectorMethods[vectorMethod].value
        );
        return chosenVectorMethods.includes('Flex');
    };

    const validateFiles = (files = [], vectorMethods = []) => {
        if (!Array.isArray(files) || files.length === 0) {
            alert('No files added');
            return false;
        }

        if (!Array.isArray(vectorMethods) || vectorMethods.length === 0) {
            alert('No vector methods selected');
            return false;
        }

        for (const file of files) {
            if (
                (file.name.includes('xlsx') || file.name.includes('csv')) &&
                vectorMethods.some((method) => method !== 'Text' && method !== 'llama')
            ) {
                alert('CSV and Excel files work only with llama/llamapipeline');
                return false
            }
        }
        return true
    }
    const filesQueueService = createFileQueueService()

    const handleSubmit = async (e) => {
        e.preventDefault();
        let chosenVectorMethods = Object.keys(uploadData.vectorMethods).filter(
            (vectorMethod) => uploadData.vectorMethods[vectorMethod].value
        );

        const filesToAdd = displayedFiles;

        if (!validateFiles(filesToAdd, chosenVectorMethods)) {
            console.error(`Invalid files or vector methods: ${filesToAdd}, ${chosenVectorMethods}`);
            return
        }
        const uploadConfig = {
            folderId: activeFolder ? activeFolder.id : null,
        }
        console.log(`Uploading for folder: ${activeFolder ? activeFolder.id : 'root'}`);
        filesQueueService.addFiles(filesToAdd, { vectorMethods: chosenVectorMethods, uploadData }, uploadConfig);

        setUploadedDocs([...uploadedDocs, ...displayedFiles]);

        setIsLoading(true);
        setIsShowStatus(true);




        onClose();
    };


    const handleTextField = (e) => {
        setDocumentData((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };
    const generateLoadMessage = () => {
        return action === 'add' ? 'adding new document' : 'editing document';
    };
    const inputsStyle = {
        style: { color: 'var(--text-clr)', opacity: '0.5' },
    };

    const updateModel = ({ target }) => {
        const { value } = target;
        setApiModel(value);
    };
    return (
        <Modal
            header={isLoading ? generateLoadMessage() : header}
            subHeader={isLoading ? 'You will be notified when the action is done' : subHeader}
            onClose={onClose}
            isOpen={isOpen}
            style={isLoading ? { height: 'auto', width: 'fit-content' } : { height: 'auto', width: 'fit-content' }}
        >
            {!isLoading ? (
                <form className="modal-content" method="POST" onSubmit={handleSubmit}>
                    <div className="flex-files">
                        {displayedFiles.map((file) => {
                            return (
                                <div className="file" key={file.id}>
                                    <div className="icon-name-container">
                                        <img src={file.name.trim().includes('pdf') ? pdfImg : wordImg} /> {file.name}
                                    </div>
                                    <button type="button" onClick={() => handleRemoveFile(file.id)}>
                                        <RemoveIcon />
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                    {action == 'add' && (
                        <>
                            {toggle === 'file' ? (
                                <label
                                    className="input-file-container"
                                    onDrop={(e) => {
                                        e.preventDefault();
                                        handleFiles(e.dataTransfer.files);
                                        handleDragLeave(e)
                                    }}
                                    onDragOver={(e) => handleDragOver(e)}
                                    onDragLeave={(e) => handleDragLeave(e)}
                                    onMouseLeave={(e) => handleDragLeave(e)}
                                >
                                    {
                                        <>
                                            {' '}
                                            <UploadFile />
                                            <input
                                                className="file-picker-input"
                                                style={{ width: '23px' }}
                                                title=" "
                                                accept=".doc, .docx, .pdf ,.xlsx ,.csv"
                                                type="file"
                                                id="files"
                                                name="filename"
                                                onChange={(e) => handleFiles(e.target.files)}
                                                multiple
                                            />
                                            Drag or Attach Your File Here (PDF or Word)
                                        </>
                                    }
                                </label>
                            ) : (
                                <TextField
                                    fullWidth
                                    InputLabelProps={inputsStyle}
                                    value={uploadData.url}
                                    name="url"
                                    variant="filled"
                                    label="Url"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    onChange={handleTextField}
                                />
                            )}
                        </>
                    )}
                    <div className="vectors-upper">
                        <div className="vectors-methods-description">
                            <h4>Advance Options </h4>
                            {isAdvancedOptionOpen ? (
                                <CloseOptionsIcon onClick={() => setIsAdvancedOptionOpen((prev) => !prev)} />
                            ) : (
                                <OpenOptionsIcon onClick={() => setIsAdvancedOptionOpen((prev) => !prev)} />
                            )}
                        </div>
                        <div className="uploaded-count">{displayedFiles.length} Files</div>
                    </div>
                    {isAdvancedOptionOpen && (
                        <>
                            <span style={{ alignSelf: 'baseline' }}>{'(optional,multiple choice)'} </span>
                            {
                                <div className="lower-part-vectors">
                                    {/* checks for ormat user to */}
                                    {company !== 'ormat.com' && (
                                        <>

                                            <button
                                                type="button"
                                                style={{ fontSize: '14px' }}
                                                className={uploadData.vectorMethods['Flex'].value ? 'active-vector' : ''}
                                                onClick={() => handleChangeVectorMethod('Flex')}
                                            >
                                                {uploadData.vectorMethods['Flex'].label}
                                            </button>

                                            <button
                                                type="button"
                                                style={{ fontSize: '14px' }}
                                                className={uploadData.vectorMethods['Text'].value ? 'active-vector' : ''}
                                                onClick={() => handleChangeVectorMethod('Text')}
                                            >
                                                {uploadData.vectorMethods.Text.label}{' '}
                                            </button>

                                            <button
                                                type="button"
                                                style={{ fontSize: '14px' }}
                                                className={uploadData.vectorMethods['Text & Image'].value ? 'active-vector' : ''}
                                                onClick={() => handleChangeVectorMethod('Text & Image')}
                                            >
                                                {uploadData.vectorMethods['Text & Image'].label}{' '}
                                            </button>

                                            {/* <button
                        type="button"
                        className={documentData.vectorMethods.llama.value ? 'active-vector' : ''}
                        onClick={() => handleChangeVectorMethod('llama')}
                      >
                        {documentData.vectorMethods.llama.label}{' '}
                      </button> */}
                                        </>
                                    )}
                                </div>
                            }
                            {isIncludeFlex() && (
                                <div className="chunks-options-container">
                                    <p>Model</p>

                                    <Select
                                        className="select-box-tool-box"
                                        size="small"
                                        id="Model"
                                        value={apiModel}
                                        label="Model"
                                        name="model"
                                        onChange={updateModel}
                                    >
                                        {['Gpt-4o', 'Gpt-4'].map((option) => (
                                            <MenuItem key={uuidv4()} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                // <div className="chunks-options-container">
                                //   <div className="single-chunk-container">
                                //     <p>Model</p>
                                //     <input
                                //       onChange={(ev) =>
                                //         setDocumentData((prev) => ({ ...prev, chunkSize: ev.target.value }))
                                //       }
                                //       value={documentData.chunkSize}
                                //       type="number"
                                //       max={600}
                                //       min={20}
                                //     />
                                //   </div>
                                // </div>
                            )}
                            <div className="chunks-options-container">
                                <div className="single-chunk-container">
                                    <p>Chunk size</p>
                                    <input
                                        onChange={(ev) =>
                                            setDocumentData((prev) => ({ ...prev, chunkSize: ev.target.value }))
                                        }
                                        value={uploadData.chunkSize}
                                        type="number"
                                        max={4000}
                                        min={20}
                                    />
                                </div>
                                <div className="single-chunk-container">
                                    <p>Overlap</p>
                                    <input
                                        onChange={(ev) =>
                                            setDocumentData((prev) => ({ ...prev, chunkOverLap: ev.target.value }))
                                        }
                                        value={uploadData.chunkOverLap}
                                        type="number"
                                        max={100}
                                        min={2}
                                    />
                                </div>
                            </div>

                            <div className="english-translation-container">
                                {uploadData.vectorLanguage === 'english (UK)' ? (
                                    <OptionSelected
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setDocumentData((prev) => ({
                                                ...prev,
                                                vectorLanguage:
                                                    prev.vectorLanguage === 'english (UK)' ? 'hebrew' : 'english (UK)',
                                            }));
                                        }}
                                        alt={'selected'}
                                    />
                                ) : (
                                    <OptionUnSelected
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setDocumentData((prev) => ({
                                                ...prev,
                                                vectorLanguage:
                                                    prev.vectorLanguage === 'english (UK)' ? 'hebrew' : 'english (UK)',
                                            }));
                                        }}
                                        alt={'not selected'}
                                    />
                                )}

                                <p>Translate document to English</p>
                            </div>

                        </>
                    )}
                    <button disabled={!displayedFiles.length || displayedFiles.length > 20} className="submit-btn-document" type="submit">
                        {action === 'add' ? 'Submit' : 'edit document'}
                    </button>
                </form>
            ) : (
                <></>
            )
                //  <ProgressBar isLoading={isLoading} isPageEnglish={true} progressPercent={progressPercent} />
            }
        </Modal>
    );
}

export default AddEditModal;
