import React, { useEffect, useState } from 'react';
import './delete-modal.scoped.css';
import { Modal } from '@/components/Modal/Modal';
import pdfImg from '@/assets/pdf-img.png';
import wordImg from '@/assets/word-img.png';
import axios from '@/configs/axios.js';
import { ReactComponent as ChatboxClose } from '@/assets/chatbox-close-icon-lm.svg';
import { markedDocumentsAtom, chosenDocumentsAtom } from '@/atoms/documents';
import { useAtom } from 'jotai';
import { ProgressBar } from '@/components/ProgressBar/ProgressBar';
import Cookies from 'js-cookie';
import { markedFoldersAtom, treeAtom } from '@/atoms/folders';
import { removeFileById } from '@/services/files/removeFile';
import { ReactComponent as FolderIcon } from '@/assets/folder-icon.svg';
import { filterDocsInFolders } from '@/services/folders//filterDocsInFolders';
import { removeFoldersById } from '@/services/folders/removeFolders';
import { userAtom } from '@/atoms/general';
import Snackbar from '@/components/Snackbar/Snackbar';
import { SnackbarProvider, useSnackbar } from 'notistack';

function DeleteModal({ isOpen, onClose, header, subHeader }) {
  const [markedDocuments] = useAtom(markedDocumentsAtom);
  const [markedFolders] = useAtom(markedFoldersAtom);
  const [user] = useAtom(userAtom);
  const [toShowFiles, setToShowFiles] = useState();
  const [toShowFolders, setToShowFolders] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [entireTree, setEntireTree] = useAtom(treeAtom);
  const [progress, setProgressPercent] = useState(0);
  console.log(toShowFiles);
  console.log(markedDocuments);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setToShowFiles(markedDocuments);
    setToShowFolders(markedFolders);
  }, []);
  const deleteDocuments = async () => {
    try {
      setIsLoading(true);

      let filesId = toShowFiles.map((file) => file.docId);
      let foldersId = toShowFolders.map((folder) => folder.id);

      let notInfoldersFiles = filterDocsInFolders(filesId, foldersId, entireTree.subfolders);
      await axios.post('/api/documents/delete-items', {
        clientId: Cookies.get('client-id'),
        userId: user.id,
        docs: filesId,

        folders: foldersId,
      });
      let newTree = removeFileById(entireTree.files, entireTree.subfolders, notInfoldersFiles);
      console.log(notInfoldersFiles);

      console.log(newTree);

      newTree = removeFoldersById(newTree.files, newTree.subfolders, foldersId);
      setEntireTree(newTree);
      onClose();
      enqueueSnackbar('Files deleted successfully', {
        content: (key, message) => <Snackbar type={'success'} message={message} />,
      });
    } catch (err) {
      enqueueSnackbar('We encountered a problem when deleting, please try again', {
        content: (key, message) => <Snackbar type={'error'} message={message} />,
      });
      console.log(err);
    }
  };
  const handleFilesForDelete = (docId) => {
    setToShowFiles((prev) => prev.filter((file) => file.docId !== docId));
  };
  const handleFolderForDelete = (folderId) => {
    setToShowFolders((prev) => prev.filter((folder) => folder.id !== folderId));
  };

  return (
    <Modal
      header={isLoading ? 'Deleting files, please wait' : header}
      subHeader={isLoading ? 'You will be notified when the action is done' : subHeader}
      onClose={onClose}
      isOpen={isOpen}
      style={isLoading ? { height: 'auto' } : {}}
    >
      {!isLoading ? (
        <>
          <div className="flex-files">
            {toShowFiles &&
              toShowFiles.map((file) => {
                return (
                  <div className="file" key={file.docId}>
                    <div className="icon-name-container">
                      <img src={file.docType.trim() === 'pdf' ? pdfImg : wordImg} /> {file.docTitle}
                    </div>
                    <button type="button" onClick={() => handleFilesForDelete(file.docId)}>
                      <ChatboxClose />
                    </button>
                  </div>
                );
              })}
            {toShowFolders &&
              toShowFolders.map((folder) => {
                return (
                  <div className="file" key={folder.id}>
                    <div className="icon-name-container">
                      {/* <img
                        src={FolderIcon}
                      />{" "} */}
                      <FolderIcon />
                      {folder.folder_name}
                    </div>
                    <button type="button" onClick={() => handleFolderForDelete(folder.id)}>
                      <ChatboxClose />
                    </button>
                  </div>
                );
              })}
          </div>
          <div className="flex-btns-actions">
            <button>cancel</button>
            <button onClick={deleteDocuments}>delete</button>
          </div>
        </>
      ) : (
        <></>
      )}
    </Modal>
  );
}

export default DeleteModal;
