const headersSettings = {
  'documents': [
    {
      name: 'headers',
      options: [
        {
          style: {
            textAlign: 'left',
            alignItems: 'center',
          },
          type: 'component',
          isSelect: true,
          isSort: false,
        },
        {
          value: 'Name',
          style: { textAlign: 'left' },
          type: 'header',
          isSort: true,
        },
        {
          value: 'Owner',
          style: {},
          type: 'header',
          isSort: true,
        },
        {
          value: 'Last Update',
          style: {},
          type: 'header',
          isSort: true,
        },
        {
          value: 'Files Size',
          style: {},
          type: 'header',
          isSort: true,
        },
      ],
    },
    { type: 'documents' },
  ],
  voice: [
    {
      name: 'headers',
      options: [
        {
          style: {
            textAlign: 'left',
            alignItems: 'center',
            // width: '100px',
          },
          type: 'component',
          isSelect: true,
          isSort: false,
        },
        {
          value: 'Name',
          style: {
            textAlign: 'left',
          },
          type: 'header',
          isSort: true,
        },
        {
          value: 'Create Date',
          style: {},
          type: 'header',
          isSort: true,
        },
        {
          value: 'Duration',
          style: {},
          type: 'header',
          isSort: true,
        },
        {
          value: 'Subject',
          style: {},
          type: 'header',
          isSort: true,
        },
        {
          value: 'Country',
          style: {},
          type: 'header',
          isSort: true,
        },
        {
          value: 'More Info',
          style: { cursor: 'auto' },
          type: 'header',
          isSort: false,
        },
      ],
    },
    { type: 'voice' },
  ],
  prompts: [
    {
      name: 'headers',
      options: [
        {
          style: {
            textAlign: 'left',
            alignItems: 'center',
          },
          type: 'component',
          isSelect: true,
          isSort: false,
        },
        {
          value: 'Name',
          style: { textAlign: 'left' },
          type: 'header',
          isSort: true,
        },
        {
          value: 'Owner',
          style: {},
          type: 'header',
          isSort: true,
        },
        {
          value: 'Last Update',
          style: {},
          type: 'header',
          isSort: true,
        },
        {
          value: 'Edit By',
          style: {},
          type: 'header',
          isSort: true,
        },
      ],
    },
    { type: 'prompts' },
  ],
  assistant: [
    {
      name: 'headers',
      options: [
        {
          style: {
            textAlign: 'left',
            // alignItems: 'center',
            // marginLeft: '8px',
            // display: 'inline-block',
            // verticalAlign: 'middle',
          },
          type: 'component',
          isSelect: true,
          isSort: false,
        },
        {
          value: 'Name',
          style: {},
          type: 'header',
          isSort: true,
        },
        {
          value: 'Owner',
          style: {},
          type: 'header',
          isSort: true,
        },
        {
          value: 'Last Update',
          style: {},
          type: 'header',
          isSort: true,
        },
        {
          value: 'Created At',
          style: {},
          type: 'header',
          isSort: true,
        },
        {
          value: 'Active',
          style: {},
          type: 'header',
          isSort: true,
        },
        {
          value: 'Status',
          style: {},
          type: 'header',
          isSort: true,
        },
        {
          value: 'Usage',
          style: {},
          type: 'header',
          isSort: false,
        },
        {
          value: '',
          style: {},
          type: 'header',
          isSort: false,
        },
      ],
    },
    { type: 'assistant' },
  ],
  admin: [
    {
      name: 'headers',
      options: [
        {
          style: {
            textAlign: 'left',
            alignItems: 'center',
          },
          type: 'component',
          isSelect: false,
          isSort: false,
        },
        {
          value: 'Group',
          style: {
            textAlign: 'left',
          },
          type: 'header',
          isSort: true,
        },
        {
          value: 'Name',
          style: {},
          type: 'header',
          isSort: false,
        },
        {
          value: 'Email',
          style: {},
          type: 'header',
          isSort: false,
        },
        {
          value: 'Title',
          style: {},
          type: 'header',
          isSort: false,
        },
        {
          value: 'Token Per Month',
          style: {},
          type: 'header',
          isSort: false,
        },
        {
          value: 'Token Used',
          style: {},
          type: 'header',
          isSort: false,
        },
        {
          value: 'Ai Model',
          style: {},
          type: 'header',
          isSort: false,
        },
      ],
    },
    { type: 'admin' },
  ],
};

export default headersSettings;