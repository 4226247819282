import axios from '@/configs/axios.js';

export const getVoiceCalls = async () => {
  try {
    const response = await axios.get(`/api/voice/get-docs`);
    return response.data;
  } catch (error) {
    console.log('Error fetching table data:', error);
  }
};
