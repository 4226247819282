import './documents-table.scoped.css';
import React, { useState, useRef } from 'react';
import pdfImg from '@/assets/pdf-img.png';
import wordImg from '@/assets/word-img.png';
import { ReactComponent as CheckActiveIcon } from '@/assets/check-icon-active.svg';
import { ReactComponent as CheckIcon } from '@/assets/check-icon-lm.svg';
import { ReactComponent as VectorLinkIcon } from '@/assets/vector-link-icon-lm.svg';
import moment from 'moment';
import { useAtom } from 'jotai';
import { uploadedDocsAtom, isShowStateAtom } from '@/atoms/upload';
import { markedDocumentsAtom } from '@/atoms/documents';
import { activeFolderAtom, markedFoldersAtom } from '@/atoms/folders';
import { ReactComponent as FolderIcon } from '@/assets/folder-icon.svg';
import VectorModal from '../VectorModal/VectorModal';
import { treeAtom, currentTreeSizeAtom } from '@/atoms/folders';
import { handleToggleFolder } from '@/services/mark/toggleMarkFolder';
import { toggleFileMark, toggleMarkAllTree } from '@/services/mark/toggleMarkFile';
import UploadStatus from '@/components/UploadStatus/UploadStatus';
import TableHeaderSort from '@/components/TableHeaderSort/TableHeaderSort';
import Tooltip from '@/components/Tooltip/Tooltip';

function DocumentsTable({
  handleFolderPress,
  documentsToShowUser,
  folders,
  isUpload,
  settings,
  isDocs,
}) {
  const spanRef = useRef(null);
  const [activeFolder] = useAtom(activeFolderAtom);
  const [markedFolders] = useAtom(markedFoldersAtom);
  const [isMarkAll, setIsMarkAll] = useState(false);
  const [markedDocuments] = useAtom(markedDocumentsAtom);
  const [entireTree, setEntireTree] = useAtom(treeAtom);
  const [uploadedDocs] = useAtom(uploadedDocsAtom);
  const [currentTreeSize] = useAtom(currentTreeSizeAtom);
  const [isShowStatus] = useAtom(isShowStateAtom);
  const [sortOrder, setSortOrder] = useState(1);
  const [openVectorPopUp, setOpenVectorPopUp] = useState({
    docId: '',
    method: '',
  });

  if (openVectorPopUp.length) console.log(openVectorPopUp.method);

  const isActiveFileCheckbox = (docId) => {
    return markedDocuments.filter((file) => file.docId === docId).length > 0;
  };

  const isActiveFolderCheckbox = (folderId) => {
    return markedFolders.filter((folder) => folder.id === folderId).length > 0;
  };

  const isValidJSONArr = (str) => {
    try {
      const arr = JSON.parse(str);
      return Array.isArray(arr);
    } catch (e) {
      return false;
    }
  };

  const handleMarkFolderChange = (e, folderId) => {
    e.stopPropagation();
    const [markedDocumentsResponse, entireTreeResponse] = handleToggleFolder(
      [...markedDocuments],
      folderId,
      [...entireTree.subfolders]
    );
    setEntireTree((prev) => ({ ...prev, subfolders: entireTreeResponse }));
    //  setMarkedDocuments(markedDocumentsResponse)
  };

  const handleMarkAllChange = (event) => {
    if (activeFolder != '') {
      handleMarkFolderChange(event, activeFolder.id);
    } else {
      event.preventDefault();
      const newTree = toggleMarkAllTree(entireTree, !isMarkAll);
      setIsMarkAll(!isMarkAll);
      setEntireTree(newTree);
    }
  };

  const handleMarkChange = (event, docId) => {
    event.preventDefault();

    const newTree = toggleFileMark(entireTree.files, entireTree.subfolders, docId);
    setEntireTree(newTree);
  };

  function handleSort(arr, key) {
    arr.sort((a, b) => {
      if (key === 'last_updated') {
        const dateA = moment(a[key], 'DD MMM, YYYY').valueOf();
        const dateB = moment(b[key], 'DD MMM, YYYY').valueOf();
        return (dateA - dateB) * sortOrder;
      } else {
        const valA = a[key].toLowerCase();
        const valB = b[key].toLowerCase();
        return (valA > valB ? 1 : valA < valB ? -1 : 0) * sortOrder;
      }
    });
    setSortOrder(-sortOrder);
  }

  return (
    <>
      <div className="table-container">
        <table>
          <thead
            style={{
              backgroundColor: 'var(--chat-background-clr)',
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}
          >
            <tr
              style={{
                top: 0,
                zIndex: 1,
              }}
            >
              {settings[0].options.map((header, index) => {
                return (
                  <TableHeaderSort
                    key={index}
                    type={header.type}
                    value={header.type === 'header' ? header.value : markedDocuments.length}
                    style={header.style}
                    handler={
                      header.type === 'header'
                        ? handleSort
                        : {
                            value:
                              (markedDocuments.length === documentsToShowUser.length ||
                                markedDocuments.length === currentTreeSize) &&
                              markedDocuments.length !== 0
                                ? true
                                : false,
                            func: handleMarkAllChange,
                          }
                    }
                    arr={documentsToShowUser}
                    arr2={folders}
                    isSelect={header.isSelect}
                    table={settings[1].type}
                    isSort={header.isSort}
                  />
                );
              })}
            </tr>
          </thead>

          <tbody>
            {folders &&
              folders.length > 0 &&
              folders.map((folder, index) => {
                return (
                  <tr onClick={(e) => handleFolderPress(e, folder.id, folder.folder_name)} key={index}>
                    <td style={{ textAlign: 'left' }}>
                      <input
                        type="checkbox"
                        id={folder.id}
                        onClick={(e) => handleMarkFolderChange(e, folder.id)}
                        className="checkbox"
                      />
                      <label
                        htmlFor={folder.id}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {isActiveFolderCheckbox(folder.id) ? <CheckActiveIcon /> : <CheckIcon />}
                      </label>
                    </td>
                    <td style={{ textAlign: 'left' }} key={index}>
                      <div className="icon-name-container">
                        <FolderIcon />
                        <span style={{ width: isDocs ? '77%' : '100%' }}>
                          {folder.folder_name}
                        </span>
                      </div>
                    </td>
                    <td style={{ textAlign: 'center' }}>Me </td>
                    <td style={{ textAlign: 'center', direction: 'ltr' }}>
                      {moment(folder.updated_at)
                        .locale('en')
                        .format('D MMM, YYYY')}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {/* {Math.round(folder.doc_size / 1000)} kb */}
                    </td>
                    {/* <td style={{textAlign:'right'}}>
                      <input type='checkbox' id={document.doc_id} onChange={(e)=>handleChange(e,document.doc_id)} className='checkbox' />
                      <label for={document.doc_id}>
                      {isActiveCheckbox(document.doc_id) ?<CheckActiveIcon/>  : <CheckIcon/> }
                      </label>
                      </td> */}
                  </tr>
                );
              })}
            {documentsToShowUser.length > 0 &&
              documentsToShowUser.map((document, index) => {
                return (
                  <tr key={index}>
                    <td style={{ textAlign: 'left' }}>
                      <input
                        type="checkbox"
                        id={document.doc_id}
                        onClick={(e) =>
                          handleMarkChange(
                            e,
                            document.doc_id,
                            document.doc_title,
                            document.doc_type,
                            document.doc_description,
                            document.vector_methods,
                            document.doc_name_origin
                          )
                        }
                        className="checkbox"
                      />
                      <label htmlFor={document.doc_id}>
                        {isActiveFileCheckbox(document.doc_id) ? <CheckActiveIcon /> : <CheckIcon />}
                      </label>
                    </td>
                    <td style={{ textAlign: 'left' }}>
                      <div className="icon-name-container">
                        <img src={document.doc_type.trim() === 'pdf' ? pdfImg : wordImg} alt="" />
                        <span style={{ width: isDocs ? '77%' : '100%' }}>{document.doc_title}</span>
                      </div>
                      {isValidJSONArr(document.vector_methods) && isActiveFileCheckbox(document.doc_id) && (
                        <div className="flex-vector-methods">
                          {JSON.parse(document.vector_methods).map((method) => {
                            return (
                              <button
                                className="vector-modal-btn"
                                onClick={() =>
                                  setOpenVectorPopUp({ docId: document.doc_id, method: method })
                                }
                              >
                                {' '}
                                <VectorLinkIcon /> {method}{' '}
                              </button>
                            );
                          })}
                        </div>
                      )}
                      {isActiveFileCheckbox(document.doc_id) && (
                        <div className="flex-vector-methods">
                          <button
                            className="vector-modal-btn"
                            onClick={() =>
                              setOpenVectorPopUp({ docId: document.doc_id, method: 'questions' })
                            }
                          >
                            {' '}
                            <VectorLinkIcon /> {'Questions'}{' '}
                          </button>
                        </div>
                      )}
                    </td>
                    <td style={{ textAlign: 'center' }}>Me </td>
                    <td style={{ textAlign: 'center', direction: 'ltr' }}>
                      {moment(document.created_at)
                        .locale('en')
                        .format('D MMM, YYYY')}
                    </td>
                    <td style={{ textAlign: 'center' }}>{Math.round(document.doc_size / 1000)} kb</td>
                    {/* <td style={{textAlign:'right'}}>

                      <input type='checkbox' id={document.doc_id} onChange={(e)=>handleChange(e,document.doc_id)} className='checkbox' />
                      <label for={document.doc_id}>
                      {isActiveCheckbox(document.doc_id) ?<CheckActiveIcon/>  : <CheckIcon/> }
                          </label>
                        </td> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {openVectorPopUp.docId !== '' && (
        <VectorModal
          docId={openVectorPopUp.docId}
          type={openVectorPopUp.method}
          isOpen={openVectorPopUp.docId !== ''}
          subHeader={'you can edit your data'}
          onClose={() => setOpenVectorPopUp({ method: '', docId: '' })}
        />
      )}
      <UploadStatus  />
    </>
  );
}

export default DocumentsTable;
