import { atom } from 'jotai';

export const callsToShowUserAtom = atom('');

export const searchCallAtom = atom('');

export const sortOptionAtom = atom('');

export const timeToggleAtom = atom(false);

export const filteredCallsAtom = atom((get) => {
    const files = get(callsToShowUserAtom);
    const searchWord = get(searchCallAtom).toLowerCase();
    const selectedOption = get(sortOptionAtom);
    const timeToggle = get(timeToggleAtom);
  
    const filteredFiles =
      searchWord.length > 0 && files.length > 0
        ? files.filter((file) => file.file_name.toLowerCase().includes(searchWord))
        : files;
  
    if (selectedOption) {
      const sortedFiles = [...filteredFiles].sort((a, b) => {
        if (selectedOption === 'Date') {
          return timeToggle
            ? new Date(b.created_at) - new Date(a.created_at)
            : new Date(a.created_at) - new Date(b.created_at);
        } else if (selectedOption === 'Name A-Z') {
          return a.file_name.localeCompare(b.file_name);
        } else if (selectedOption === 'Name Z-A') {
          return b.file_name.localeCompare(a.file_name);
        }
        return 0;
      });
      return sortedFiles;
    }
    return filteredFiles;
  });