import './bots-main.scoped.css';
import { useEffect, useState } from 'react';
import { userAtom, propertiesAtom, skeletonAssistantAtom } from '@/atoms/general';
import LowerNavbar from '@/components/BotsTab/LowerNavbar/LowerNavbar';
import UploadStatus from '@/components/UploadStatus/UploadStatus';
import BotsTable from '@/components/BotsTab/BotsTable/BotsTable';
import BotManage from '@/components/BotsTab/BotManage/BotManage';
import { isShowStateAtom } from '@/atoms/upload';
import axios from '@/configs/axios.js';
import { useAtom } from 'jotai';
import {
  markedBotsAtom,
  updateMarkedBotsAtom,
  searchBotAtom,
  sortOptionAtom,
  botsToShowUserAtom,
  filteredBotsAtom,
  timeToggleAtom,
} from '@/atoms/bots';
import { getTranslates, createTranslates } from '@/api/translates';
import { getBotData, createOrUpdateBot, getUserBots } from '@/api/bots';
import { getDefaultPromptsData } from '@/api/prompts';
import SkeletonTable from '@/components/SkeletonTable/SkeletonTable';
import SearchInput from '@/components/SearchInput/SearchInput';
import SelectInput from '@/components/SelectInput/SelectInput';
import { selectOptions } from '@/constants/table/selectOptions';
import headersSettings from '@/constants/table/headers';

let analysisPrompt, gradePrompt, relevantPrompt, hackPrompt;

const BotsMain = ({ isOpenBotCreator, setIsOpenBotCreator }) => {
  const [isShowStatus, setIsShowStatus] = useAtom(isShowStateAtom);
  const [properties] = useAtom(propertiesAtom);
  const [, setMarkedBots] = useAtom(updateMarkedBotsAtom);
  const [isSkeleton, setIsSkeleton] = useAtom(skeletonAssistantAtom);
  const [markedBots] = useAtom(markedBotsAtom);
  const [user] = useAtom(userAtom);
  const [, setBotsDetails] = useAtom(botsToShowUserAtom);
  const [, setTimeToggle] = useAtom(timeToggleAtom);
  const [filteredBots] = useAtom(filteredBotsAtom);
  const [isOpen, setIsOpen] = useState(false);
  const [characterPrompt, setCharacterPrompt] = useState('');
  const [botUrl, setBotUrl] = useState('');
  const [searchQuery, setSearchQuery] = useAtom(searchBotAtom);
  const [selectedOption, setSelectedOption] = useAtom(sortOptionAtom);

  useEffect(() => {
    getAllBotsUser();
    setBotUrl(properties.urls?.assistant_url);
  }, []);

  const getAllBotsUser = async () => {
    try {
      setIsSkeleton(true);
      const userId = user.id;
      const data = await getUserBots(userId);
      setBotsDetails([...data]);
    } catch (err) {
      console.log(err);
      setIsSkeleton(false);
    } finally {
      setIsSkeleton(false);
    }
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setTimeToggle((prev) => !prev);
    setIsOpen(false);
  };

  const openBotCreator = () => {
    setIsOpenBotCreator(true);
  };

  const handleSearchChange = ({ target }) => {
    setSearchQuery(target.value);
  };

  const getCharacterPrompt = async (bot_description, bot_audience) => {
    const characterPrompt = await axios.put(`/api/bots/character-prompt`, {
      bot_description,
      bot_audience,
    });
    setCharacterPrompt(characterPrompt.data.characterPrompt);
  };

  const getBotDefaultPrompts = async () => {
    const defaultPrompts = await getDefaultPromptsData();

    analysisPrompt = defaultPrompts.analysis_prompt;
    gradePrompt = defaultPrompts.grade_prompt;
    hackPrompt = defaultPrompts.hack_prompt;
    relevantPrompt = defaultPrompts.relevant_answer_prompt;
    setCharacterPrompt(defaultPrompts.character_prompt.content);
  };

  const duplicateBots = async (chosenBots) => {
    chosenBots.forEach(async (chosenBot) => {
      const data = await getBotData(chosenBot.botId);
      const response = await createOrUpdateBot({
        userId: user.id,
        botId: '',
        firstMessageValue: data.firstMessageValue,
        promptsSettingsFiltered: data.promptsSettings,
        toolKitSettings: data.toolKitSettings,
        endMessageValue: data.endMessageValue,
        errorMessageValue: data.errorMessageValue,
        chosenDocs: data.chosenDocs,
        botData: {
          bot_name: `Copy of - ${data.bot_name}`,
          bot_description: data.description,
          bot_audience: data.audience,
        },
      });
      const botId = response;
      const chosenBotTranslates = await getTranslates(chosenBot.botId);
      const translates = chosenBotTranslates.map((translate) => ({ ...translate, bot_id: botId }));
      if (translates.length > 0) await createTranslates(translates);
    });
    await getAllBotsUser();
    setSelectedOption(selectOptions[0]);
    setMarkedBots({ type: 'markAllBots', payload: { val: [] } });
    setSearchQuery('');
  };

  return (
    <>
      {isOpenBotCreator ? (
        <BotManage
          getAllBotsUser={getAllBotsUser}
          botId={markedBots[0]?.botId}
          setIsOpenBotCreator={setIsOpenBotCreator}
          setSearchQuery={setSearchQuery}
          prompts={{
            analysisPrompt: analysisPrompt,
            gradePrompt: gradePrompt,
            characterPrompt: characterPrompt,
            relevantPrompt: relevantPrompt,
            hackPrompt: hackPrompt,
          }}
        />
      ) : (
        <>
          <div className="search-container">
            <SearchInput isDisable={isSkeleton} handleSearch={handleSearchChange} value={searchQuery} />
            <SelectInput
              isDisable={isSkeleton}
              isDropdown={isOpen}
              handleDropdown={toggleDropdown}
              handleClick={handleOptionClick}
              options={selectOptions}
              selected={selectedOption}
            />
          </div>
          <div className="bots-table">
            {isSkeleton && !filteredBots.length ? (
              <SkeletonTable />
            ) : (
              <BotsTable botsToShowUser={filteredBots} botUrl={botUrl} settings={headersSettings['assistant']} />
            )}
          </div>

          <LowerNavbar
            setIsOpen={setIsOpen}
            getAllBotsUser={getAllBotsUser}
            setIsOpenBotCreator={setIsOpenBotCreator}
            openBotCreator={openBotCreator}
            getCharacterPrompt={getCharacterPrompt}
            getBotDefaultPrompts={getBotDefaultPrompts}
            setBotsDetails={setBotsDetails}
            duplicateBots={duplicateBots}
          />
          {isShowStatus && <UploadStatus onClose={() => setIsShowStatus(!isShowStatus)} />}
        </>
      )}
    </>
  );
};

export default BotsMain;
