import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const promptsFromSession = JSON.parse(sessionStorage.getItem('prompts'));
let countChosenPrompts = 0;
if (promptsFromSession) {
  countChosenPrompts = Object.values(promptsFromSession).reduce((a, item) => a + item, 0);
}
export const countChosenPromptsAtom = atom(countChosenPrompts);

export const searchAtom = atom("");

export const sortOptionAtom = atom("");

export const promptsToShowUserAtom = atom([]);

export const timeToggleAtom = atom(false);

export const filteredPromptsAtom = atom((get) => {
  const prompts = get(promptsToShowUserAtom);
  const searchWord = get(searchAtom).toLowerCase();
  const selectedOption = get(sortOptionAtom);
  const timeToggle = get(timeToggleAtom);

  const filteredPrompts =
    searchWord.length > 0 && prompts.length > 0
      ? prompts.filter((prompt) => prompt.title.toLowerCase().includes(searchWord))
      : prompts;

  if (selectedOption) {
    const sortedPrompts = filteredPrompts.sort((a, b) => {
      if (selectedOption === 'Date') {
        return timeToggle
          ? new Date(b.created_at) - new Date(a.created_at)
          : new Date(a.created_at) - new Date(b.created_at);
      } else if (selectedOption === 'Name A-Z') {
        return a.title.localeCompare(b.title);
      } else if (selectedOption === 'Name Z-A') {
        return b.title.localeCompare(a.title);
      }
      return 0;
    });
    return sortedPrompts;
  }
  return filteredPrompts;
});

export const markedPromptsAtom = atom([]);

export const chosenPromptsAtom = atomWithStorage('prompts', []);

export const promptsOpenPopUpAtom = atom('');

export const chosenPromptsIdsAtom = atom((get) => {
  const prompts = get(chosenPromptsAtom);
  return prompts.map((item) => item.prompt_id);
});

///atom that represents the folder that the user pressed (it is string if in top folder)
export const activeFolderAtom = atom('');

/// atom that represents the folders that inside the active folder
export const userFoldersAtom = atom('');

// atom that represents the breadcrumbs path, consist of objects with the fields {parentId,folderId,name}
export const pathAtom = atom([]);

//atom that represents the folders that the users marked (for delete or other actions in the future)
export const markedFoldersAtom = atom([]);
