import React, { useEffect, useRef } from 'react';
import { ReactComponent as ModalCloseIcon } from '@/assets/modal-close-icon-lm.svg';
import { ReactComponent as ModalBackIcon } from '@/assets/modal-back-btn.svg';

import './modal.scoped.css';

export function Modal({
  header,
  subHeader,
  children,
  isOpen,
  onClose,
  style = {},
  isTimeout,
  backFunction,
}) {
  const modalRef = useRef(null);

  // Close the modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Add event listener for clicks outside
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  // Handle timeout if specified
  useEffect(() => {
    if (isTimeout) {
      const timer = setTimeout(() => {
        onClose();
      }, 4000);
      return () => clearTimeout(timer); // Clear timeout on unmount or when modal closes
    }
  }, [isTimeout, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-full-screen">
      <div ref={modalRef} className="modal-container" style={style}>
        {backFunction && (
          <button onClick={backFunction} className="modal-back-btn">
            <ModalBackIcon />
          </button>
        )}
        <button onClick={onClose} className="modal-close-btn">
          <ModalCloseIcon />
        </button>
        <div className="modal-upper">
          <h2>{header}</h2>
          <span>{subHeader}</span>
        </div>
        {children}
      </div>
    </div>
  );
}
