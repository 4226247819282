// {
//     "files": [
//         {
//             "doc_id": "17192162363156920da697633edb855fbd6768f76eaf4",
//             "created_at": "2024-06-24T05:03:56.345Z",
//             "owner_id": "17IFebzdA1                                                                                                                                                                                                                                                      ",
//             "doc_name_origin": "××××¤××××§×.docx",
//             "doc_title": "ביופיזיקה - חדש לתשאול",
//             "doc_size": "22629",
//             "folder_id": null,
//             "doc_description": "",
//             "doc_type": "docx                                                                                                                                                                                                                                                            ",
//             "vector_methods": "[\"llamaPipeline\"]",
//  MOCK DATA
//             "doc_summery": "",
//             "doc_summery_modified_by": null,
//             "doc_summery_modified_at": null,
//             "tags": []
//         }
//     ],
//     "subfolders": [
//             {
//                 "id": 285,
//                 "folder_name": "road6_eldad",
//                 "owner_id": "17IFebzdA1",
//                 "created_at": "2024-10-09T11:50:45.100Z",
//                 "updated_at": "2024-10-09T11:50:45.100Z",
//                 "parent_id": null,
//                 "files": [
//                     {
//                         "doc_id": "1730883413208-2d4828404441527d4eb8c0873acd4dba",
//                         "created_at": "2024-11-06T06:57:03.085Z",
//                         "owner_id": "17IFebzdA1                                                                                                                                                                                                                                                      ",
//                         "doc_name_origin": "Text.docx",
//                         "doc_title": "Text.docx",
//                         "doc_size": "13257",
//                         "folder_id": 285,
//                         "doc_description": null,
//                         "doc_type": "docx                                                                                                                                                                                                                                                            ",
//                         "vector_methods": "[\"Text\"]",
//                         "doc_summery": null,
//                         "doc_summery_modified_by": null,
//                         "doc_summery_modified_at": null,
//                         "tags": []
//                     }
//                 ],
//                 "subfolders": []
//             }
//     ]
// }
export function addFileToFolder(folderId, file, data) {
    function addFileToFolderRecursive(subfolders, folderId, file) {
        for (let subfolder of subfolders) {
            if (subfolder.id === folderId) {
                subfolder.files.push(file);
                return true;
            }
            if (addFileToFolderRecursive(subfolder.subfolders, folderId, file)) {
                return true;
            }
        }
        return false;
    }

    if (folderId === null) {
        data.files.push(file);
    } else {
        addFileToFolderRecursive(data.subfolders, folderId, file);
    }

    return data;
}
