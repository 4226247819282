import headersFieldsMapping from '@/constants/table/headersFieldsMapping';

const setTableHeaders = (table, value, arr, arr2, handler) => {
  const fields = headersFieldsMapping[table]?.[value];

  if (!fields) return; // Exit if no fields found for this combination

  fields.forEach((field, index) => {
    // Call handler on arr for the first field, and on arr2 if there's a second field and arr2 is defined
    // For example for documents table that has the files array and the folder array (contain 2 in the table).
    if (index === 1 && arr2) {
      handler(arr2, field);
    } else {
      handler(arr, field);
    }
  });
};

export default setTableHeaders;
