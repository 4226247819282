import "./conversion-table.scoped.css";
import React, { useState, useMemo } from "react";
import { useAtom } from "jotai";
import moment from "moment";
import { TableHeaderSort } from "./ConversionTableHeader";
import { TableRow } from "./ConversionTableRow";
import {
  activeTranslatesAtom,
  updateTranslatesAtom,
} from "@/atoms/translates";

const ConversionTable = ({ translates, settings }) => {
  const [, setTranslates] = useAtom(updateTranslatesAtom);
  const [activeTranslates] = useAtom(activeTranslatesAtom);
  const [sortOrder, setSortOrder] = useState(1);

  const checkboxAll = useMemo(() => {
    return (
      translates.length > 0 &&
      translates.every((translate) =>
        activeTranslates.some((active) => active.id === translate.id)
      )
    );
  }, [translates, activeTranslates]);

  const handleCheckboxAll = () => {
    setTranslates({
      type: "updateActiveAll",
      payload: { val: !checkboxAll },
    });
  };

  function handleSort(arr, key) {
    arr.sort((a, b) => {
      if (key === "last_updated") {
        const dateA = moment(a[key], "DD MMM, YYYY").valueOf();
        const dateB = moment(b[key], "DD MMM, YYYY").valueOf();
        return (dateA - dateB) * sortOrder;
      } else {
        const valA = a[key].toLowerCase();
        const valB = b[key].toLowerCase();
        return (valA > valB ? 1 : valA < valB ? -1 : 0) * sortOrder;
      }
    });
    setSortOrder(-sortOrder);
  }

  return (
    <>
      <div className="table-container">
        <table>
          <thead>
            <tr
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                zIndex: 1,
              }}
            >
              {settings[1].options.map((header, index) => {
                return (
                  <TableHeaderSort
                    key={index}
                    type={header.type}
                    value={
                      header.type === "header"
                        ? header.value
                        : activeTranslates.length
                    }
                    style={header.style}
                    handler={
                      header.type === "header"
                        ? handleSort
                        : { value: checkboxAll, func: handleCheckboxAll }
                    }
                    arr={translates}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {translates.map((translate, index) => (
              <TableRow key={index} translate={translate} settings={settings} />
            ))}
            {/* <tr>
              <td>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "115px",
                  }}
                >
                  <AddNewIcon onClick={handleNewRow} />
                  <div>{"Add New"}</div>
                </div>
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ConversionTable;
