import "./expandable-table.scoped.css";
import React, { useState } from "react";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Pagination,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as ArrowIcon } from "@/assets/keyboard-arrow.svg";
import { useAtom } from "jotai";
import { groupsAtomWithUpdates } from "@/atoms/admin";
import { v4 as uuidv4 } from "uuid";
import {
  updateGroupToken,
  updateGroupModel,
  updateGroupUsersToken,
  updateGroupUsersModel,
} from '@/api/admin'
import { TableInnerRow } from './TableInnerRow/TableInnerRow'

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      backgroundColor: "var(--main-undertone-clr)",
    },
  },
}));

const ExpandableTable = ({ group, settings, isExpanded, onExpand }) => {
  const [, setGroups] = useAtom(groupsAtomWithUpdates);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 8;
  const classes = useStyles();

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  const getModelsLabels = (modelsArr) => {
    const newArr = modelsArr
      .map((model) => {
        const result = settings[0].options.find((option) => option.value === model)
        return result ? result.label : null
      })
      .filter((option) => option !== null)
    return newArr
  }

  const getToken = (tokenValue) => {
    const chosen = settings[1].options.find((option) => option.value === tokenValue)
    return chosen
  }

  const handleTokenChange = (event) => {
    const newTokenValue = event.target.value
    updateGroupToken({ groupId: group.group_id, token: newTokenValue })
    updateGroupUsersToken({ groupId: group.group_id, token: newTokenValue })
    setGroups({
      type: 'updateGroupToken',
      payload: { groupId: group.group_id, val: newTokenValue },
    })
  }

  const handleModelChange = (event) => {
    const newModelsValue = event.target.value
    updateGroupModel({ groupId: group.group_id, model: newModelsValue })
    updateGroupUsersModel({ groupId: group.group_id, model: newModelsValue })
    setGroups({
      type: 'updateGroupModel',
      payload: { groupId: group.group_id, val: newModelsValue },
    })
  }

  const indexOfLastRow = currentPage * rowsPerPage
  const indexOfFirstRow = indexOfLastRow - rowsPerPage
  const currentRows = group.users.slice(indexOfFirstRow, indexOfLastRow)

  return (
    <>
      <tr className="group-row">
        <td style={{ display: 'contents' }}>
          <button
            style={{
              marginLeft: '40%',
              marginTop: '18px',
              transform: isExpanded ? '' : 'rotate(180deg)',
              transition: 'transform 150ms ease',
              border: 'none',
            }}
            onClick={onExpand}
          >
            <ArrowIcon />
          </button>
        </td>
        <td>
          <b>{group.group_name}</b>
        </td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
        <td>
          <Select
            className="select-box-tool-box"
            size="medium"
            id={`${Math.floor(Math.random() * 25)}`}
            value={getToken(group.default_max_tokens_per_user).value}
            style={{ width: '100px' }}
            label="Token"
            name="token"
            onChange={handleTokenChange}
          >
            {settings[1].options.map((option) => (
              <MenuItem key={uuidv4()} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </td>
        <td>--</td>
        <td>
          <Select
            className="select-box-tool-box"
            size="medium"
            id={`${Math.floor(Math.random() * 25)}`}
            multiple
            value={group.default_model}
            renderValue={(selected) => selected.join(', ')}
            label="Model"
            name="model"
            onChange={handleModelChange}
          >
            {settings[0].options.map((option) => (
              <MenuItem key={uuidv4()} value={option.value}>
                <Checkbox checked={group.default_model.includes(option.value)} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </td>
      </tr>

      {isExpanded && (
        <>
          {currentRows.map((user) => (
            <TableInnerRow key={user.id} user={user} group={group} settings={settings} />
          ))}
          {group.users.length > rowsPerPage && (
            <tr>
              <td colSpan="8">
                <div className="pagination-container">
                  <Pagination
                    count={Math.ceil(group.users.length / rowsPerPage)}
                    classes={{ ul: classes.ul }}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </div>
              </td>
            </tr>
          )}
        </>
      )}
    </>
  )
}

export default ExpandableTable
