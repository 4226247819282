import React from 'react';
import PropTypes from 'prop-types';
import './CircularProgress.css';
// import UploadProgressIcon from '../UploadProgressIcon/UploadProgressIcon';
import { ReactComponent as UploadIcon } from '@/assets/upload-load-icon.svg';
const CircularProgressBar = ({ radius = 25, percentage, strokeColor = "rgba(249, 109, 223, 1)", borderColor = "grey", fillColor  = "transparent", spin = false, label = "Processing..." }) => {
  const stroke = radius / 10;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = percentage !== undefined
    ? circumference - (percentage / 100) * circumference
    : 0;
  const containerSize = radius * 2;
  return (
    <div className='progress-container' style={{ width: containerSize, height: containerSize }}>
       <UploadIcon style={{ position: 'absolute', right: "4px" }} />
      <svg
        height={radius * 2}
        width={radius * 2}
        style={{ position: 'absolute', zIndex: 1 }}
      >
        <circle
          stroke={borderColor}
          fill={fillColor}
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      <svg
        height={radius * 2}
        width={radius * 2}
        className={spin ? 'spinner' : ''}
        style={{ position: 'absolute', zIndex: 2 }}
      >
        <circle
          stroke={strokeColor}
          fill={fillColor}
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </div>
  );
};

CircularProgressBar.propTypes = {
  percentage: PropTypes.number,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['left', 'right']),
};

export default CircularProgressBar;
