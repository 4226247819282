import { atom } from 'jotai'
import axios from '@/configs/axios.js';
import { useAtom } from 'jotai'
import { documentsToShowUserAtom } from '@/atoms/documents'
import { activeFolderAtom, userFoldersAtom, pathAtom } from '@/atoms/folders'
import { userAtom, loadingAtom, errorAtom, skeletonDataAtom } from '@/atoms/general'
import { treeAtom } from '@/atoms/folders'

export const getDocumentsAndFoldersAtom = atom(null, async (get, set, val) => {
  const user = get(userAtom)
  let entireTree = get(treeAtom)

  try {
    let response
    //// requests the file tree from server only in first time and update the tree atom
    if (!entireTree) {
      set(skeletonDataAtom, true)
      response = await axios.get(`/api/documents/get-docs-and-folders-tree/${user.id}`)
      set(treeAtom, response.data)
      entireTree = get(treeAtom)
    }
  } catch (err) {
    console.log(err)
    set(skeletonDataAtom, false)
  } finally {
    set(skeletonDataAtom, false)
  }
})

export function findActiveFolderAndNesting(topLevelFolders, activeId) {
  // if(!activeId){
  //     return topLevelFolders
  // }
  for (let folder of topLevelFolders) {
    if (folder.id === activeId) {
      // Found the active folder
      return folder
    } else {
      // Recursively check in subfolders
      let foundFolder = searchSubfolders(folder.subfolders, activeId)
      if (foundFolder) return foundFolder
    }
  }
  // Return null if the folder is not found
  return null
}

function searchSubfolders(subfolders, activeId) {
  for (let subfolder of subfolders) {
    if (subfolder.id === activeId) {
      return subfolder
    }
    let foundFolder = searchSubfolders(subfolder.subfolders, activeId)
    if (foundFolder) return foundFolder
  }
  return null
}
