import './prompts-table.scoped.css';
import React, { useState } from 'react';
import promptImg from '@/assets/table-prompt-icon.png';
import { ReactComponent as CheckActiveIcon } from '@/assets/check-icon-active.svg';
import { ReactComponent as CheckIcon } from '@/assets/check-icon-lm.svg';
import moment from 'moment';
import { useAtom } from 'jotai';
import { isShowStateAtom } from '@/atoms/upload';
import { markedPromptsAtom } from '@/atoms/prompts';
import UploadStatus from '@/components/UploadStatus/UploadStatus';
import TableHeaderSort from '@/components/TableHeaderSort/TableHeaderSort';

function PromptTable({ handleFolderPress, promptsToShowUser, settings }) {
  const [isShowStatus, setIsShowStatus] = useAtom(isShowStateAtom);
  const [markedPrompts, setMarkedPrompts] = useAtom(markedPromptsAtom);
  const [sortOrder, setSortOrder] = useState(1);

  const isActiveCheckbox = (prompt_id) => {
    return markedPrompts.filter((item) => item.prompt_id === prompt_id).length > 0;
  };

  const handleMarkChange = (event, prompt_id, title, description, content, isAdmin) => {
    event.preventDefault();
    const markedFilteredPrompts = markedPrompts.filter((item) => item.prompt_id === prompt_id);
    if (markedFilteredPrompts.length) {
      setMarkedPrompts((prev) => prev.filter((item) => item.prompt_id !== prompt_id));
    } else {
      setMarkedPrompts((prev) => [
        ...prev,
        {
          prompt_id: prompt_id,
          description: description,
          title: title,
          content: content,
          isAdmin: isAdmin,
        },
      ]);
    }
  };

  const handleMarkAllChange = (e) => {
    e.preventDefault();
    promptsToShowUser.map((prompt) => {
      handleMarkChange(
        e,
        prompt.prompt_id,
        prompt.title,
        prompt.description,
        prompt.content,
        prompt.isAdmin
      );
    });
  };

  function handleSort(arr, key) {
    arr.sort((a, b) => {
      if (key === 'last_updated') {
        const dateA = moment(a[key], 'DD MMM, YYYY').valueOf();
        const dateB = moment(b[key], 'DD MMM, YYYY').valueOf();
        return (dateA - dateB) * sortOrder;
      } else {
        const valA = a[key].toLowerCase();
        const valB = b[key].toLowerCase();
        return (valA > valB ? 1 : valA < valB ? -1 : 0) * sortOrder;
      }
    });
    setSortOrder(-sortOrder);
  }

  return (
    <>
      <div className="table-container">
        <table>
          <thead
            style={{
              backgroundColor: 'var(--chat-background-clr)',
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}
          >
            <tr
              style={{
                top: 0,
                zIndex: 1,
              }}
            >
              {settings[0].options.map((header, index) => {
                return (
                  <TableHeaderSort
                    key={index}
                    type={header.type}
                    value={header.type === 'header' ? header.value : markedPrompts.length}
                    style={header.style}
                    handler={
                      header.type === 'header'
                        ? handleSort
                        : {
                            value:
                              markedPrompts.length === promptsToShowUser.length &&
                              markedPrompts.length !== 0
                                ? true
                                : false,
                            func: handleMarkAllChange,
                          }
                    }
                    arr={promptsToShowUser}
                    isSelect={header.isSelect}
                    table={settings[1].type}
                    isSort={header.isSort}
                  />
                );
              })}
            </tr>
          </thead>

          <tbody>
            {promptsToShowUser.length > 0 &&
              promptsToShowUser.map((prompt, index) => {
                return (
                  <tr key={index}>
                    <td style={{ textAlign: 'left' }}>
                      <input
                        type="checkbox"
                        id={prompt.prompt_id}
                        onClick={(e) =>
                          handleMarkChange(
                            e,
                            prompt.prompt_id,
                            prompt.title,
                            prompt.description,
                            prompt.content,
                            prompt.isAdmin
                          )
                        }
                        className="checkbox"
                      />
                      <label htmlFor={prompt.prompt_id}>
                        {isActiveCheckbox(prompt.prompt_id) ? <CheckActiveIcon /> : <CheckIcon />}
                      </label>
                    </td>
                    <td style={{ textAlign: 'left' }}>
                      <div className="icon-name-container">
                        <img src={promptImg} />

                        {prompt.title}
                      </div>
                    </td>
                    <td style={{ textAlign: 'center' }}>Me</td>
                    <td style={{ textAlign: 'center', direction: 'ltr' }}>
                      {moment(prompt.updated_At)
                        .locale('en')
                        .format('D MMM, YYYY')}
                    </td>

                    <td style={{ textAlign: 'center' }}>{prompt.changed_by && 'Me'}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {isShowStatus && <UploadStatus onClose={()=>setIsShowStatus(!isShowStatus)} />}
    </>
  );
}

export default PromptTable;
