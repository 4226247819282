import './table-header-sort.scoped.css';
import React, { useState } from 'react';
import { ReactComponent as SortArrowIcon } from '@/assets/sort-arrow-icon.svg';
import { ReactComponent as CheckActiveIcon } from '@/assets/check-icon-active.svg';
import { ReactComponent as CheckIcon } from '@/assets/check-icon-lm.svg';
import setTableHeaders from '@/hooks/setTableHeaders';

const TableHeaderSort = ({ type, arr, arr2, value, style, handler, isSelect, isSort, table }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onSortClick = () => {
    setIsExpanded(!isExpanded);
    setTableHeaders(table, value, arr, arr2, handler);
  };

  return type === 'header' ? (
    <th style={{ ...style, padding: '13px 10px' }}>
      <div style={{ cursor: isSort ? 'pointer' : 'auto' }} onClick={isSort ? onSortClick : undefined}>
        {value}
        <button
          style={{
            transform: isExpanded ? 'rotate(180deg)' : '',
            backgroundColor: 'var(--chat-background-clr)',
            border: 'none',
            marginLeft: '10px',
          }}
        >
          {isSort && <SortArrowIcon />}
        </button>
      </div>
    </th>
  ) : (
    <th style={style}>
      <input type="checkbox" id={`checkboxId-all`} className="checkbox" />
      {isSelect && (
        <div
          onClick={handler.func}
          style={{ marginLeft: '10px', display: 'inline-block', verticalAlign: 'middle' }}
        >
          {handler.value ? <CheckActiveIcon /> : <CheckIcon />}
        </div>
      )}
      <div style={{ marginLeft: '10px', display: 'inline-block', verticalAlign: 'middle' }}>
        {isSelect && `(${value ? value : 0})`}
      </div>
    </th>
  );
};

export default TableHeaderSort;
