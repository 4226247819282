import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { findActiveFolderAndNesting } from '@/api/documents';
import { treeAtom, activeFolderAtom } from './folders';
import { getMarkedFiles } from '@/services/mark/getMarkedFiles';

export const documentsToShowUserAtom = atom((get) => {
  let entireTree = get(treeAtom);
  let activeFolder = get(activeFolderAtom);
  let content;

  // if(activeFolder)
  //   console.log('activeFolder:', activeFolder);
  // if(entireTree)
  //   console.log("entireTree:",entireTree);

  if (entireTree) {
    if (activeFolder) {
      content = findActiveFolderAndNesting(entireTree.subfolders, activeFolder.id);

      if (content != null) {
        return content.files;
      } else {
        return entireTree.files;
      }
    } else {
      return entireTree.files;
    }
  } else {
    return [];
  }
});

export const searchAtom = atom("");

export const sortOptionAtom = atom("");

export const timeToggleAtom = atom(false);

export const filteredDocsAtom = atom((get) => {
  const docs = get(documentsToShowUserAtom);
  const searchWord = get(searchAtom).toLowerCase();
  const selectedOption = get(sortOptionAtom);
  const timeToggle = get(timeToggleAtom);


  const filteredDocs =
    searchWord.length > 0 && docs.length > 0
      ? docs.filter((doc) => doc.doc_title.toLowerCase().includes(searchWord))
      : docs;

  if (selectedOption) {
    const sortedDocs = filteredDocs.sort((a, b) => {
      if (selectedOption === 'Date') {
        return timeToggle
          ? new Date(b.created_at) - new Date(a.created_at)
          : new Date(a.created_at) - new Date(b.created_at);
      } else if (selectedOption === 'Name A-Z') {
        return a.doc_title.localeCompare(b.doc_title);
      } else if (selectedOption === 'Name Z-A') {
        return b.doc_title.localeCompare(a.doc_title);
      }
      return 0;
    });
    return sortedDocs;
  }
  return filteredDocs;
});


export const markedDocumentsAtom = atom((get) => {
  let entireTree = get(treeAtom);
  if (entireTree) {
    return getMarkedFiles(entireTree.files, entireTree.subfolders);
  } else {
    return [];
  }
});

export const chosenDocumentsAtom = atomWithStorage('docs', []);

export const documentsOpenPopUpAtom = atom('');

export const documentHistoryAtom = atom('');

export const chosenDocumentsIdsAtom = atom((get) => {
  const documents = get(chosenDocumentsAtom);
  return documents.map((item) => item.doc_id);
});
