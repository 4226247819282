const headersFieldsMapping = {
  documents: {
    Name: ['doc_title', 'folder_name'],
    Owner: ['owner_id'],
    'Last Update': ['created_at', 'updated_at'],
    'Files Size': ['doc_size'],
  },
  prompts: {
    Name: ['title'],
    Owner: ['owner_id'],
    'Last Update': ['changed_at'],
    'Edit By': ['changed_by'],
  },
  voice: {
    Name: ['file_name'],
    'Create Date': ['created_at'],
    Duration: ['duration'],
    Subject: ['error'],
    Country: ['country'],
  },
  assistant: {
    Name: ['bot_name'],
    Owner: ['owner'],
    'Last Update': ['lastUpdate'],
    'Created At': ['createdAt'],
    Active: ['is_active'],
    Status: ['status'],
    Usage: ['usage'],
  },
  admin: {
    Group: ['group_name'],
    Name: [],
    Email: [],
    Title: [],
    'Token Per Month': [],
    'Token Used': [],
    'Ai Model': [],
  },
};

export default headersFieldsMapping;
