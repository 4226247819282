import { ReactComponent as FailIcon } from '@/assets/upload-status-fail.svg';
import { ReactComponent as SuccessIcon } from '@/assets/upload-status-success.svg';
import RemoveIcon from '@mui/icons-material/Close';
import Progress from '../../Progress/CircularProgress';
import { PROCESSING_FILE_STATUS } from '../../../services/fileQueueService';

function StatusIcon({ status, percentage = 25, spin = false }) {
    return (
        <>
            {status === PROCESSING_FILE_STATUS.FAIL && <FailIcon style={{ cursor: 'auto' }} />}
            {status === PROCESSING_FILE_STATUS.SUCCESS && <SuccessIcon style={{ cursor: 'auto' }} />}
            {status === PROCESSING_FILE_STATUS.LOADING && <Progress radius={16} percentage={percentage} spin={spin} />}
            {status === PROCESSING_FILE_STATUS.PENDING && <RemoveIcon style={{ cursor: 'pointer', }} />}
        </>
    );
}

export default StatusIcon;
