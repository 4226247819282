import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const botsFromSession = JSON.parse(sessionStorage.getItem('bots'));
let countChosenBots = 0;
if (botsFromSession) {
  countChosenBots = Object.values(botsFromSession).reduce((a, item) => a + item, 0);
}

export const botsToShowUserAtom = atom([]);

export const searchBotAtom = atom('');

export const sortOptionAtom = atom('');

export const timeToggleAtom = atom(false);

export const filteredBotsAtom = atom((get) => {
  const bots = get(botsToShowUserAtom).filter((bot) => bot.bot_name !== null);
  const searchWord = get(searchBotAtom).toLowerCase();
  const selectedOption = get(sortOptionAtom);
  const timeToggle = get(timeToggleAtom);

  const filteredBots =
    searchWord.length > 0 && bots.length > 0
      ? bots.filter((bot) => bot.bot_name.toLowerCase().includes(searchWord))
      : bots;

  if (selectedOption) {
    const sortedBots = [...filteredBots].sort((a, b) => {
      if (selectedOption === 'Date') {
        return timeToggle
          ? new Date(b.createdAt) - new Date(a.createdAt)
          : new Date(a.createdAt) - new Date(b.createdAt);
      } else if (selectedOption === 'Name A-Z') {
        return a.bot_name.localeCompare(b.bot_name);
      } else if (selectedOption === 'Name Z-A') {
        return b.bot_name.localeCompare(a.bot_name);
      }
      return 0;
    });
    return sortedBots;
  }
  return filteredBots;
});

export const markedBotsAtom = atom([]);

export const updateMarkedBotsAtom = atom(null, (get, set, action) => {
  const markedBots = get(markedBotsAtom);
  const { val } = action.payload;
  switch (action.type) {
    case 'markAllBots':
      set(markedBotsAtom, val);
      break;
    case 'markBot':
      const isFound = markedBots.some((markedBot) => markedBot.botId === val.botId);
      if (val && val !== '') {
        if (isFound) {
          set(markedBotsAtom, markedBots.filter((markedBot) => markedBot.botId !== val.botId));
        } else {
          set(markedBotsAtom, [...markedBots, val]);
        }
      }
      break;
  }
});

export const chosenBotsAtom = atomWithStorage('bots', []);

export const botsOpenPopUpAtom = atom('');

export const botCreatorDataAtom = atom({ bot_name: '', description: '', bot_audience: '' });

export const toolKitSettingsAtom = atomWithStorage('botCreatorToolkit', {
  is_active: 'No',
  status: 'Development',
  should_bot_translate: 'None',
  ui_language: 'English (UK)',
  questions_selected: [],
  validations_selected: [],
  question_choice: [],
  embedding_version: 'Embedding-002',
});
