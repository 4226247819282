import axios from '@/configs/axios.js';
import React, { useState, useRef, useEffect } from 'react';
import './lower-navbar.scoped.css';
import { ReactComponent as EditIcon } from '@/assets/edit-docs-icon.svg';
import { ReactComponent as DownloadIcon } from '@/assets/download-icon.svg';
import { ReactComponent as HistoryIcon } from '@/assets/history-docs-icon.svg';
import { ReactComponent as ShareIcon } from '@/assets/share-icon.svg';
import { ReactComponent as DeleteIcon } from '@/assets/delete-icon.svg';
import { ReactComponent as AddDocumentIcon } from '@/assets/chatbox-newdocs-icon-lm.svg';
import { useAtom } from 'jotai';
import { markedPromptsAtom, promptsOpenPopUpAtom } from '@/atoms/prompts';
import FilledBtn from '@/components/FilledBtn/FilledBtn';
import OutlineBtn from '@/components/OutlineBtn/OutlineBtn';
import { pathAtom } from '@/atoms/folders';

function LowerNavbar({}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [, setDocumentsOpenPopUp] = useAtom(promptsOpenPopUpAtom);
  const [markedDocuments] = useAtom(markedPromptsAtom);
  const isSingleDoc = markedDocuments.length === 1;

  // Reference for the dropdown container
  const dropdownContainerRef = useRef(null);

  // Close the dropdown when clicking outside of btn-and-dropdown-container
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="bottom-flex">
      <div className="btn-and-dropdown-container" ref={dropdownContainerRef}>
        <FilledBtn onClick={() => setIsDropdownOpen((prev) => !prev)}>
          <AddDocumentIcon />
          New
        </FilledBtn>
        {isDropdownOpen && (
          <div className="dropdown">
            <button
              onClick={() => {
                setDocumentsOpenPopUp('add-prompt');
                setIsDropdownOpen(false);
              }}
            >
              Prompt
            </button>
            {/* <button onClick={() => setDocumentsOpenPopUp('add-folder')}> Folder</button> */}
          </div>
        )}
      </div>
      {markedDocuments.length > 0 && (
        <div className="bottom-left-btns">
          <>{/* <OutlineBtn onClick={handleDownload}><DownloadIcon />Download</OutlineBtn> */}</>
          {isSingleDoc && (
            <>
              <OutlineBtn onClick={() => setDocumentsOpenPopUp('edit-prompt')}>
                {markedDocuments[0].isAdmin ? <></> : <EditIcon />}
                {markedDocuments[0].isAdmin ? 'Inspect' : 'Edit'}
              </OutlineBtn>
              {/* <OutlineBtn><HistoryIcon />History</OutlineBtn> */}
            </>
          )}
          {/* <OutlineBtn><ShareIcon />Share</OutlineBtn> */}
          {!markedDocuments.some((document) => document.isAdmin) && (
            <OutlineBtn onClick={() => setDocumentsOpenPopUp('delete-prompt')}>
              <DeleteIcon />
              Delete
            </OutlineBtn>
          )}
        </div>
      )}
    </div>
  );
}

export default LowerNavbar;
