import './search-input.scoped.css';

const SearchInput = ({ isDisable, value, handleSearch }) => {
  return (
    <input
      className="search-input"
      type="text"
      placeholder="Search..."
      value={value}
      onChange={handleSearch}
      disabled={isDisable}
      style={{ color: isDisable ? 'var(--light-grey-clr)' : 'var(--black-clr)' }}
    />
  );
};

export default SearchInput;
